import { db } from '../firebase-config'
import { collection, getDoc, getDocs, doc, setDoc, deleteDoc, query, orderBy, limit, where } from 'firebase/firestore/lite'
import { setWineImg } from 'firebaseKit/storage'

// List of wines
export default function useWinesApi () {
  const WinesCol = collection(db, 'wines')

  const getWines = async () => {
    const q = query(WinesCol, where('status', '==', 'live'))
    const querySnapshot = await getDocs(q)
    const WineList = querySnapshot.docs.map(doc => doc.data())

    return WineList
  }

  const getWine = async (wineId) => {
    const docRef = doc(db, 'wines', wineId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      return docSnap.data()
    } else {
      console.log('No such document!')
    }
  }

  const getWinesByWynery = async (wineryId) => {
    const q = query(WinesCol, where('status', '==', 'live'), where('wineryId', '==', wineryId), orderBy('name'))
    const querySnapshot = await getDocs(q)
    let data = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      data = [...data, doc.data()]
    })
    return data
  }

  const addWine = async (wine, successCallback, errorCallback, status = 'live') => {
    try {
      let image = wine.image
      if (typeof wine.image === 'object') {
        image = await setWineImg(wine.image)
      }
      const wineRef = doc(WinesCol)
      await setDoc(wineRef, { ...wine, image, status, id: wineRef.id })
      const wineDoc = await getDoc(wineRef)

      console.log('wineRef', wineDoc.data())
      successCallback()
      return wineDoc.data()
    } catch (err) {
      errorCallback()
    }
  }

  const editWine = async (winesData, successCallback, errorCallback, status = 'live') => {
    try {
      let image = winesData.image
      if (typeof winesData.image === 'object') {
        image = await setWineImg(winesData.image)
      }
      await setDoc(doc(db, 'wines', winesData.id), { ...winesData, image, status })

      const docSnap = await getDoc(doc(db, 'wines', winesData.id))
      successCallback()
      if (docSnap.exists()) {
        return docSnap.data()
      } else {
        console.log('No Winery added!')
      }
    } catch (err) {
      errorCallback()
    }
  }

  const deleteWine = async (wine, callback, status = 'removed') => {
    await setDoc(doc(db, 'wines', wine.id), { ...wine, status })
      .then(result => callback())
    // await deleteDoc(doc(db, "wines", wine));
  }

  return { getWines, getWine, addWine, editWine, deleteWine, getWinesByWynery }
}
