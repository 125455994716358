import React from 'react'
import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/material/styles'

const Btn = styled(MuiButton)(({ theme }) => ({
  width: '100%',
  border: '2px solid black',
  borderRadius: '50px',
  padding: theme.spacing(2),
  color: 'black'
}))

const Button = React.forwardRef(function Button (props, btnRef) {
  return <Btn ref={btnRef} {...props} >{props.children}</Btn>
})

export default Button
