import useApi from 'api/apiRequests'
import React, { useState, useContext, createContext, useEffect } from 'react'

export const GameContext = createContext()
const GameMode = ({ children }) => {
  const { getGame, getPlayer, getRound, joinGame, startGame, deleteGame, getPlayerPointsPerRound } = useApi()
  const [multiplayer, setMultiPlayer] = useState({ loading: true, value: false })
  const [gameData, setGameData] = useState(null
    // finished: null,
    // gameId: null,
    // host: null,
    // id: null,
    // players: null,
    // started: null,
    // winery: null,
    // wines: null
  )
  const [currentRound, setCurrentRound] = useState({ loading: true })
  const [player, setPlayer] = useState({ isHost: null, nickname: null, isReady: null, color: null })
  const [snapOnGameRoom, setSnapOnGameRoom] = useState(false)
  const [snapOnScoringRoom, setSnapOnScoringRoom] = useState(false)
  const [snapOnWineRoom, setSnapOnWineRoom] = useState(false)

  const getStoragePlayerData = async () => {
    const storageData = JSON.parse(localStorage.getItem('multiplayer'))
    if (storageData) {
      const { isHost, nickname, isReady, color } = storageData
      setPlayer({ isHost, nickname, sixDigitCode: storageData?.sixDigitCode, isReady, color, totalPoints: storageData?.totalPoints })
    }
    return storageData
  }
  const getPoints = async (gameData) => {
    let points = 0
    if (gameData) {
      for (const round of gameData.wines) {
        if (round.players.length) {
          const playerData = (await getPlayerPointsPerRound(gameData.gameId, round.id, player.nickname)).data
          points += playerData?.points || 0
        }
      }
    }
    return points
  }

  const setStoragePlayerData = ({ isHost }, nickname, gameId, sixDigitCode, color) => {
    try {
      localStorage.setItem('multiplayer', JSON.stringify({
        isHost,
        nickname,
        gameId,
        sixDigitCode,
        isReady: false,
        color
      }))
    } catch (e) {
      throw new Error('multiplayer storage error:', e)
    }
  }

  const getGameRequest = async (sixDigitCode, nickname, isHost) => {
    try {
      if (!isHost && nickname) {
        const playerData = await getPlayer(sixDigitCode, nickname)
        // console.log('playerData', playerData)
      }
      const data = (await getGame(sixDigitCode))?.data
      setGameData(data)
      if (data) await getCurrentRound(data)
      return data
    } catch (e) {
      console.log('error get game request', e.message)
      removeGameDataState()
    }
  }
  const joinGameRequest = async (gameId, nickName) => {
    const response = (await joinGame({ gameId, nickName }))?.data
    if (response) {
      const { game, playerData, error } = response
      if (error) return { error }
      setStoragePlayerData({ isHost: playerData.isHost }, playerData.nickName, game.id, game.gameId, playerData.color)
      setGameData(game)
      return response
    }
    return null
  }
  const startGameRequest = async (gameId, nickName) => {
    const response = (await startGame(gameId, nickName))?.data
    if (!response?.error) {
      const multi = JSON.parse(localStorage.getItem('multiplayer'))
      localStorage.setItem('multiplayer', JSON.stringify({ ...multi, isReady: response?.isReady }))
      setPlayer({ ...player, isReady: response?.isReady })
      return response
    }
    return { error: response?.error }
  }
  const deleteGameRequest = async (gameId) => {
    const boolean = (await deleteGame(gameId))?.data.data
    if (boolean) removeGameDataState()
    return boolean
  }
  const removeGameDataState = (navigate) => {
    try {
      localStorage.removeItem('multiplayer')
      setGameData(null)
      setPlayer({ isHost: null, nickname: null, isReady: null, color: null })
      if (navigate) navigate('/game-mode')
    } catch (err) {
      console.log('error removing game data')
    }
  }

  const getCurrentRound = async (gameData) => {
    const roundMatch = gameData.wines.find(({ started, finished }) => started === true && finished === false)
    const roundData = roundMatch ? (await getRound(gameData.id, roundMatch.id))?.data : null
    roundMatch ? setCurrentRound({ loading: false, ...roundData.round }) : setCurrentRound({ loading: false })
    return roundMatch
  }

  const getMultiplayerData = async (navigate) => {
    const storageData = await getStoragePlayerData()
    if (storageData) {
      const { sixDigitCode, nickname, isHost } = storageData
      const data = await getGameRequest(sixDigitCode, nickname, isHost)
      return data
    }
    return null
  }

  useEffect(() => {
    const multi = JSON.parse(localStorage.getItem('multiplayer'))
    if (multi && !multiplayer.value) {
      setMultiPlayer({ loading: false, value: true })
    } else if (multiplayer.loading) setMultiPlayer({ loading: false, value: false })
  }, [gameData])

  const [guessed, setGuessed] = useState({ guessed: false, wineryName: null, points: null })

  return (
    <GameContext.Provider value={{
      multiplayer,
      setMultiPlayer,
      gameData,
      setGameData,
      currentRound,
      setCurrentRound,
      player,
      setPlayer,
      getPoints,
      getStoragePlayerData,
      setStoragePlayerData,
      getMultiplayerData,

      getGameRequest,
      joinGameRequest,
      startGameRequest,
      deleteGameRequest,
      removeGameDataState,

      snapOnGameRoom,
      snapOnScoringRoom,
      snapOnWineRoom,

      setSnapOnGameRoom,
      setSnapOnScoringRoom,
      setSnapOnWineRoom,

      guessed,
      setGuessed
    }}>
      {children}
    </GameContext.Provider>
  )
}

export default GameMode

export const useGameMode = () => useContext(GameContext)
