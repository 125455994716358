import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'

const HowToTest = ({ title, details, tasteTips }) => {
  const theme = useTheme()

  return (
    <Box sx={{ mx: 4 }}>
        {
            title &&
            <>
                <Typography style={theme.title} component="h1" variant="h5" sx={[tasteTips ? { pt: 2 } : { pt: 10 }, { mb: 2 }]}>
                    How to taste
                </Typography>
                <Typography style={theme.paragraph} component="p" variant="p" paragraph>
                    Exploring wine with friends is one of life’s great pleasures.
                </Typography>
                <Typography style={theme.paragraph} component="p" variant="p" paragraph>
                    Choosing the right bottle for the moment should be simple, but depending on where it’s grown and who grows it, one
                    wine can taste completely different to another. The Wine Smash approach to tasting allows you to compare multiple
                    different wines so you can discover which styles you prefer.
                    <br /> <br />
                    Follow these steps and you’ll be tasting like a pro in no time.
                </Typography>
            </>
        }

        {
            details &&
            <>
                <Typography sx={[tasteTips && { mb: 8 }, { pt: 2, mb: 14 }]} style={theme.paragraph} component="p" variant="p" paragraph>
                    In Wine Smash you’ll find different wine tasting
                    boxes, each of which offer 5 wines to play our
                    Wine Tasting Game.
                    <br /> <br />
                    The game is based around 6 different criteria that
                    wine experts look for when tasting and is a great
                    way to hone your tasting skills. Play against your
                    friends and see how your skills compare with our
                    Wine Expert’s.
                    <br /> <br />
                    Or just sit back, drink and enjoy the wine with
                    good company.
                </Typography>
            </>
        }

    </Box>
  )
}

export default HowToTest
