import axiosInstance from './constants'

const useApi = () => {
  const createGame = async (body) => {
    try {
      return (await axiosInstance.post('multiplayer/create', body))?.data
    } catch (error) {
      throw new Error('error creating game')
    }
  }

  const getGame = async (gameId) => {
    try {
      return await axiosInstance.get(`multiplayer/${gameId}`)
    } catch (e) {
      throw new Error('error finding game', e.message)
    }
  }

  const getPlayer = async (gameId, player) => {
    try {
      return await axiosInstance.get(`players/${gameId}/${player}`)
    } catch (e) {
      throw new Error('error finding current player', e.message)
    }
  }

  const deleteGame = async (gameId) => {
    try {
      return await axiosInstance.delete(`multiplayer/${gameId}`)
    } catch (e) {
      throw new Error('error deleting game', e.message)
    }
  }

  const joinGame = async (body) => {
    try {
      return await axiosInstance.put('multiplayer/join', body)
    } catch (e) {
      throw new Error('error joining game', e.message)
    }
  }

  const startGame = async (gameId, nickName) => {
    try {
      return await axiosInstance.put(`multiplayer/${gameId}`, { nickName })
    } catch (e) {
      throw new Error(e.message)
    }
  }

  const startRound = async (gameId, roundId, players) => {
    try {
      return await axiosInstance.put('multiplayer/round/join', { gameId, roundId, players })
    } catch (e) {
      throw new Error('error starting game', e.message)
    }
  }

  const finishRound = async (gameId, roundId, host) => {
    try {
      return await axiosInstance.put('multiplayer/round/finish', { gameId, roundId, host })
    } catch (e) {
      throw new Error('error starting game', e.message)
    }
  }

  const addPlayerPoints = async (gameId, roundId, player) => {
    try {
      return await axiosInstance.put('multiplayer/round/playerPoints', { gameId, roundId, player })
    } catch (e) {
      throw new Error('error adding points to round', e.message)
    }
  }

  const getPlayerPointsPerRound = async (gameId, roundId, player) => {
    try {
      return await axiosInstance.post('multiplayer/round/playerPoints', { gameId, roundId, player })
    } catch (e) {
      throw new Error('error getting round points', e.message)
    }
  }

  const getRound = async (gameId, roundId) => {
    try {
      return await axiosInstance.get(`multiplayer/round/${gameId}/${roundId}`)
    } catch (e) {
      throw new Error('error getting all player round points', e.message)
    }
  }
  const getPlayersPoints = async (gameId, isHost) => {
    try {
      return await axiosInstance.put('multiplayer/playerPoints', { gameId, isHost })
    } catch (e) {
      throw new Error('error getting all player game points', e.message)
    }
  }
  const addPlayerExtraPoints = async (gameId, player, extraPoints) => {
    try {
      return await axiosInstance.put('multiplayer/extraPoints', { gameId, player, extraPoints })
    } catch (e) {
      throw new Error('error adding extraPoints', e.message)
    }
  }
  const getRoundWins = async (gameId) => {
    try {
      return await axiosInstance.get(`multiplayer/${gameId}/playerRounds`)
    } catch (e) {
      throw new Error('error getting all player round wins', e.message)
    }
  }
  const removePlayerFromGame = async (gameId, player) => {
    try {
      return await axiosInstance.delete(`multiplayer/${gameId}/${player}`)
    } catch (e) {
      throw new Error('error removing player from game', e.message)
    }
  }

  const showRoundResults = async (gameId, roundId, host) => {
    try {
      return await axiosInstance.put('multiplayer/round/showResults', { gameId, roundId, host })
    } catch (e) {
      throw new Error('error starting game', e.message)
    }
  }

  return { createGame, getGame, getPlayer, deleteGame, joinGame, startGame, startRound, addPlayerPoints, getPlayerPointsPerRound, getRound, getPlayersPoints, addPlayerExtraPoints, showRoundResults, finishRound, getRoundWins, removePlayerFromGame }
}

export default useApi
