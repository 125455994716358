import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'

import { getFirestore, collection, getDoc, getDocs, doc, setDoc, deleteDoc } from 'firebase/firestore/lite'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyD0_rCrYf9d1mDMZI27VmrG0AaTjapGo3M',
  authDomain: 'ws-prototype.firebaseapp.com',
  projectId: 'ws-prototype',
  storageBucket: 'ws-prototype.appspot.com',
  messagingSenderId: '438797496687',
  appId: '1:438797496687:web:2b355c2880e6e1717cb89a',
  measurementId: 'G-GNFFPPWLLJ'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const db = getFirestore(app)
const auth = getAuth()
const storage = getStorage(app)

export { app, db, auth, storage, collection, getDoc, getDocs, doc, setDoc, deleteDoc }
