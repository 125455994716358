import { storage } from '../firebase-config'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'

const storageRef = ref(storage)
const wineriesRef = ref(storageRef, 'wineries')
const wineriesImgRef = ref(wineriesRef, 'images')
const wineriesLogosRef = ref(wineriesRef, 'logos')

const winesRef = ref(storageRef, 'wines')

const uploadImgs = async (imageRef, file) => {
  try {
    await uploadBytes(imageRef, file)
    const urlToImg = await getDownloadURL(ref(imageRef))
    return urlToImg
  } catch (err) {
    console.log(err)
  }
  // return url
}
export const setWineImg = async (file) => {
  const imageRef = ref(winesRef, file.name)
  const img = await uploadImgs(imageRef, file)
  return img
}
export const SetWineryImg = async (file) => {
  const imageRef = ref(wineriesImgRef, file.name)
  const img = await uploadImgs(imageRef, file)
  return img
}
export const SetWineryLogo = async (file) => {
  const imageRef = ref(wineriesLogosRef, file.name)
  const img = await uploadImgs(imageRef, file)
  return img
}
