import React, { useState, useEffect, createContext, useContext } from 'react'

import useWineriesApi from 'firebaseKit/wineries'
import * as _ from 'lodash'

const WineryContext = createContext()

const WineryProvider = ({ children }) => {
  const { getWineries } = useWineriesApi()
  const [wineries, setWineries] = useState(null)
  // Winery data
  const getWineryList = async () => await getWineries().then(list => {
    list = _.sortBy(list, 'index')
    setWineries(list)
  })

  const setFullyScoredWinery = (wineryName) => {
    wineries.map(winery => {
      if (winery.name === wineryName) {
        winery.fullyScored = true
      }
      return winery
    })
  }

  useEffect(() => {
    getWineryList()
  }, [])

  const setWineryPreferences = (winery, scoringPreference) => {
    const prePreferencesArr = JSON.parse(localStorage.getItem('scoringPreference'))
    const preference = JSON.stringify({
      ...prePreferencesArr,
      [winery]: {
        scoringPreference
      }
    })
    localStorage.setItem('scoringPreference', preference)
  }
  const getWineryPreference = (winery) => {
    const wineriesObj = JSON.parse(localStorage.getItem('scoringPreference'))
    for (const key in wineriesObj) {
      if (key === winery) {
        return wineriesObj[key]
      }
    }
    return null
  }

  const value = {
    wineries,
    setWineries,
    setFullyScoredWinery,

    setWineryPreferences,
    getWineryPreference
  }

  return (
    <WineryContext.Provider value={value}>{children}</WineryContext.Provider>
  )
}

export default WineryProvider

export const useWineryContext = () => {
  return useContext(WineryContext)
}
