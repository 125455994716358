import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  imageIcon: {
    //   height: '100%'
  },
  iconRoot: {
    textAlign: 'center'
  }
})

const Steps = ({ theme }) => {
  const classes = useStyles()

  return (
    <Box sx={{ py: 2, ml: 4 }}>
            {
                stepList.map((list, i) => {
                  const { step, title, details, logo } = list
                  return (
                        <Grid container key={i} >
                            <Grid item xs={2} sx={{ pt: 4 }}>
                                <Icon classes={{ root: classes.iconRoot }} sx={{}}>
                                    <img className={classes.imageIcon} src={logo}/>
                                </Icon>
                            </Grid>
                            <Grid item xs={10} sx={{ py: 2, borderTop: i === 0 ? 1 : 0, borderBottom: 1, borderColor: '#2e2a26' }}>
                                <Grid item xs={12} sx={{ }}>
                                    <Typography style={theme.paragraph} component="p" variant="subtitle">{ step }</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ pb: 1 }}>
                                    <Typography style={theme.title} component="h1" variant="h5">{ title }</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ pr: 4 }}>
                                    {
                                        details.map((detail, i) => <Typography key={i} style={theme.paragraph} component="p" variant="p" >
                                        { detail } { details.length > 1 && <><br /><br /></> }
                                    </Typography>)
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                  )
                })
            }
    </Box>
  )
}

export default Steps

const stepList = [
  {
    step: 'Step 1',
    title: 'Look',
    details: ['You can’t tell much from just looking at it, so give it a swirl to release the aromas and get your nose in the glass!'],
    logo: '/onBoardingSteps/lookIcon.svg'
  },
  {
    step: 'Step 2',
    title: 'Smell',
    details: ['Wine is entertainment for your nose. Bring the glass up as close as you can, then take in a slow, deep breath. Then take another.', 'Does its aroma remind you of something? A particular fruit, herb or spice? Sometimes smells can be quite general – sometimes surprisingly specific.'],
    logo: '/onBoardingSteps/smellIcon.svg'
  },
  {
    step: 'Step 3',
    title: 'Taste',
    details: ['Now take a sip, and move it around your mouth for a moment before you swallow. Pay attention to how it feels – does it make your mouth water? Does it leave a drying or warming sensation after you swallow?', 'Is it sweet or dry? Thick or thin? Rough or smooth? Do new flavours and aromas appear once it’s in your mouth?'],
    logo: '/onBoardingSteps/tasteIcon.svg'
  },
  {
    step: 'Step 4',
    title: 'Think',
    details: ['Tasting wine is just drinking, slowed down. Reflect for a moment – how does it make you feel? Every wine here is loved by someone – which ones speak to you?', 'Our tasting game is a great way to hone your tasting skills. See how you compare against your friends and our expert.', 'Now it’s time to taste with Wine Smash!'],
    logo: '/onBoardingSteps/thinkIcon.svg'
  }
]
