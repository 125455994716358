import useWinesApi from 'firebaseKit/wines'
import React, { useEffect, useState, createContext, useContext } from 'react'

import { useWineryContext } from './WineryProvider'

const WineContext = createContext()

const WineProvider = ({ children }) => {
  const [wines, setWines] = useState(null)
  const [isWineScored, setIsWineScored] = useState({ name: null })

  const setWineList = async (winesArr) => {
    const wines = winesArr?.sort((a, b) => a.index < b.index ? -1 : 1)
    if (wines) setWines(wines)
  }
  // script
  const { wineries, setWineries } = useWineryContext()
  const { getWines } = useWinesApi()
  const scoring = {
    points: 10,
    score: {
      acidity: 2,
      alcohol: 2,
      body: 3,
      oak: 1,
      ripeness: 2,
      tannins: 2
    },
    extraPoints: 5
  }
  const fullScoringScript = async () => { // Script to score all wines.
    const winesList = await getWines()
    for (const wine of winesList) {
      const previousScoringObject = JSON.parse(localStorage.getItem('scoring'))
      const previousRatingObject = JSON.parse(localStorage.getItem('rating'))
      localStorage.setItem('scoring', JSON.stringify({
        ...previousScoringObject,
        [wine.name]: scoring
      }))
      localStorage.setItem('rating', JSON.stringify({
        ...previousRatingObject,
        [wine.name]: 2.5
      }))
    }

    const wineriesFullyScoredList = wineries && wineries.map(winery => {
      return { ...winery, fullyScored: true }
    })
    setWineries(wineriesFullyScoredList)
  }
  // useEffect(() => {
  //   fullScoringScript()
  // }, [])

  const value = {
    wines,
    setWines,
    setWineList,
    isWineScored,
    setIsWineScored
  }

  return (
    <WineContext.Provider value={value}>{children}</WineContext.Provider>
  )
}

export default WineProvider

export const useWineContext = () => {
  return useContext(WineContext)
}
