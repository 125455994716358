import { db } from '../firebase-config'
import { collection, getDoc, getDocs, doc, setDoc, addDoc, query, orderBy, where, limit } from 'firebase/firestore/lite'

import { SetWineryImg, SetWineryLogo } from 'firebaseKit/storage'
// Wineries Api
export default function useWineriesApi () {
  const WineriesCol = collection(db, 'wineries')

  const getWineries = async () => {
    const q = query(WineriesCol, where('status', '==', 'live'))

    const querySnapshot = await getDocs(q)
    const WineriesList = querySnapshot.docs.map(doc => doc.data())

    return WineriesList
  }

  const getWinery = async (wineryId) => {
    const docRef = doc(db, 'wineries', wineryId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      return docSnap.data()
    } else {
      console.log('No such document!')
    }
  }

  const addWinery = async (winery, callback, errorCallback, status = 'live') => {
    try {
      let image = winery.image; let logo = winery.logo
      if (typeof winery.image === 'object') {
        image = await SetWineryImg(winery.image)
      }
      if (typeof winery.logo === 'object') {
        logo = await SetWineryLogo(winery.logo)
      }
      const wineryRef = doc(WineriesCol)
      await setDoc(wineryRef, { ...winery, image, logo, status, id: wineryRef.id })
      const wineryDoc = await getDoc(wineryRef)

      console.log('wineryRef', wineryDoc.data())
      callback()
      return wineryDoc.data()
    } catch (err) {
      errorCallback()
    }
  }

  const editWinery = async (winery, callback, errorCallback, status = 'live') => {
    try {
      let image = winery.image; let logo = winery.logo
      if (typeof winery.image === 'object') {
        image = await SetWineryImg(winery.image)
      }
      if (typeof winery.logo === 'object') {
        logo = await SetWineryLogo(winery.logo)
      }
      await setDoc(doc(db, 'wineries', winery.id), { ...winery, image, logo, status })
      const docSnap = await getDoc(doc(db, 'wineries', winery.id))
      callback()
      if (docSnap.exists()) {
        return docSnap.data()
      } else {
        console.log('No such document!')
      }
    } catch (err) {
      errorCallback()
    }
  }

  const deleteWinery = async (winery, callback, status = 'removed') => {
    await setDoc(doc(db, 'wineries', winery.id), { ...winery, status })
      .then(result => callback())
  }

  return { getWineries, getWinery, addWinery, editWinery, deleteWinery }
}
